import React from 'react';
import styles from './styles.css';
import Menu from '../../components/Menu';
import Elipse from '../../components/Elipse';

export default function Home(){

    return (
        <>
            <header className='container'>                
                <div className='logoBack'>

                </div>

            </header>
            
        </>
    )
}