import React from 'react';
import Home from './pages/Home';
import Menu from './components/Menu';
import Body from './pages/Body';

function App() {
  return (
    <>
      <Home />
      <div className='content'>
        <Body/>
      </div>
    </>
  );
}

export default App;
