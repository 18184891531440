import React from 'react';
import styles from './styles.css';

export default function Body(){

    return (
        <div>
            <div className="btn">
                <input type="button"   onClick={(e) => {
                        e.preventDefault();
                        window.open("https://wa.me/5511997566552?text=Oi!%20Gostaria%20de%20receber%20atendimento.", "_blank")
                    }} className="btnAgendar" value="Entre em contato para adquirir nossos produtos"/>
            </div>
        </div>
    )
}